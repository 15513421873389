import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo.png';
import '../Header.css';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const headerContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0px',
    justifyContent: 'space-between',
  };

  const logoStyle = {
    marginRight: '20px',
  };

  return (
    <header>
      <div style={headerContainerStyle}>
        <img src={logo} alt="Shore Shine Window Cleaning Logo" style={logoStyle} />
        <div className="title-container">
          <Link to="/" className="full-title" style={{ margin: 0 }}>Shore Shine Window Cleaning</Link>
          <Link to="/" className="short-title" style={{ margin: 0 }}>Shore Shine</Link>
        </div>
        <div className="hamburger-menu" onClick={toggleMenu}>
          <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
          <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
          <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
        </div>
      </div>
      <nav className={`nav-links ${menuOpen ? 'open' : ''}`}>
        <Link to="/" onClick={toggleMenu}>Home</Link>
        <Link to="/about" onClick={toggleMenu}>About</Link>
        <Link to="/showcase" onClick={toggleMenu}>Showcase</Link>
      </nav>
    </header>
  );
};

export default Header;
