import React from 'react';

const Arrow = ({ direction, clickFunction }) => {
  return (
    <div className={`arrow ${direction}`} onClick={clickFunction}>
      {direction === 'left' ? '<' : '>'}
    </div>
  );
};

export default Arrow;
