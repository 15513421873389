import React from 'react';
import image1 from '../assets/images/image1.jpg';
import image2 from '../assets/images/image2.jpg';

const ShowcasePage = () => {
  return (
    <main>
      <section>
        <h2>Our Work</h2>
        <div className="gallery">
          <img src={image1} alt="Clean window 1" />
          <img src={image2} alt="Clean window 2" />
          {/* Add more images as needed */}
        </div>
      </section>
    </main>
  );
};

export default ShowcasePage;
