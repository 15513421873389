import React from 'react';

const ImageSlide = ({ image }) => {
  return (
    <div className="image-slide">
      <img src={image.url} alt={image.alt} />
      <p className="caption">{image.caption}</p>
    </div>
  );
};

export default ImageSlide;
