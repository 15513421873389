import React from 'react';

const Dots = ({ currentIndex, goToSlide, totalSlides }) => {
  return (
    <div className="dots">
      {Array.from({ length: totalSlides }).map((_, index) => (
        <span
          key={index}
          className={`dot ${currentIndex === index ? 'active' : ''}`}
          onClick={() => goToSlide(index)}
        ></span>
      ))}
    </div>
  );
};

export default Dots;
