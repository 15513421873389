import React, { useEffect, useState } from 'react';
import GoogleMap from 'google-map-react';

const center = {
  lat: 41.72122288589501,
  lng: -70.14971903837493,
};

const Map = ({ latlng = center, defaultZoom = 9 }) => {
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [zoom, setZoom] = useState(defaultZoom);

  useEffect(() => {
    // Function to determine if the device is mobile
    const isMobile = () => window.innerWidth <= 768;

    // Adjust zoom level based on device
    const adjustZoomLevel = () => {
      if (isMobile()) {
        setZoom(8); // Set a lower zoom level for mobile devices
      } else {
        setZoom(defaultZoom); // Default zoom level for desktops
      }
    };

    // Adjust zoom level on component mount and window resize
    adjustZoomLevel();
    window.addEventListener('resize', adjustZoomLevel);

    return () => {
      window.removeEventListener('resize', adjustZoomLevel);
    };
  }, [defaultZoom]);

  useEffect(() => {
    if (map && maps) {
      const circle = new maps.Circle({
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        map,
        center: latlng,
        radius: 40000,
      });

      map.addListener('zoom_changed', () => {
        const currentZoom = map.getZoom();
        const newRadius = 40000 / Math.pow(2, currentZoom - 9);
        circle.setRadius(newRadius);
      });
    }
  }, [map, maps, latlng]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div className="map-container">
        <GoogleMap
          bootstrapURLKeys={{ key: 'AIzaSyA3j6IUMCmAp0VzirSHDRVbkZDYibc3Mpk' }}
          zoom={zoom}
          center={latlng}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            setMap(map);
            setMaps(maps);
          }}
        />
      </div>
    </div>
  );
};

export default Map;
