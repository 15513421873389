import React from 'react';

const AboutPage = () => {
  return (
    <main>
      <section>
        <h2>About Us</h2>
        <p>Shore Shine Window Cleaning is dedicated to providing the best window cleaning services</p>
      </section>
    </main>
  );
};

export default AboutPage;
