import React, { useState } from 'react';

const QuotePage = () => {
  const [client_name, set_client_name] = useState('');
  const [first_floor_num_windows, set_first_floor_num_windows] = useState(0);
  const [second_and_above_floor_num_windows, set_second_and_above_floor_num_windows] = useState(0);
  const [quote, set_quote] = useState(null);

  const handle_change = (event) => {
    const { name, value } = event.target;
    if (name === 'client_name' || name === 'first_floor_num_windows' || name === 'second_and_above_floor_num_windows') {
      set_quote(null);
      if (name === 'client_name') {
        set_client_name(value);
      } else if (name === 'first_floor_num_windows' || name === 'second_and_above_floor_num_windows') {
        const new_num_windows = parseInt(value);
        if (!isNaN(new_num_windows)) {
          if (name === 'first_floor_num_windows') {
            set_first_floor_num_windows(new_num_windows);
          } else {
            set_second_and_above_floor_num_windows(new_num_windows);
          }
        }
      }
    }
  };

  const get_quote = () => {
    const first_floor_window_rate = 10;
    const second_and_above_floor_window_rate = 15;
    const special_discount_to_andrews = 20;
    let total_quote = first_floor_num_windows * first_floor_window_rate  + second_and_above_floor_num_windows * second_and_above_floor_window_rate;

    if (client_name.toLowerCase() === "andrew" && total_quote > special_discount_to_andrews) {
        alert("Special Bonus!");
      total_quote = total_quote - special_discount_to_andrews;
    }
    set_quote(total_quote);
  };

  return (
    <main>
      <section>
        <h2>Quote Page</h2>
        <p>Let us Help you!</p>
        <form>
          <label for="client_name">Name Please:</label>
          <input
            type="text"
            id="client_name"
            name="client_name"
            value={client_name}
            onChange={handle_change}
            required
          />
          <br />
          <br />
          <label for="first_floor_num_windows">Number of First Floor Windows:</label>
          <input
            type="number"
            id="first_floor_num_windows"
            name="first_floor_num_windows"
            value={first_floor_num_windows}
            onChange={handle_change}
            required
          />
          <br />
          <br />
          <label for="second_and_above_floor_num_windows">Number of Second and Above Floor Windows:</label>
          <input
            type="number"
            id="second_and_above_floor_num_windows"
            name="second_and_above_floor_num_windows"
            value={second_and_above_floor_num_windows}
            onChange={handle_change}
            required
          />
          <br />
          <br />
          <button type="button" onClick={get_quote}>
            Get Quote
          </button>
          <br />
          <br />
          {quote !== null && (
            <p>
              Hi {client_name}, we gladly estimated your quote at: ${quote.toFixed(2)}
            </p>
          )}
        </form>
      </section>
    </main>
  );
};

export default QuotePage;
