import React, { useState, useEffect, useRef } from 'react';
import ImageSlide from './ImageSlide';
import Arrow from './Arrow';
import Dots from './Dots';

const images = [
  { url: '/assets/image1.jpg', alt: 'Image 1', caption: 'What a cutie 😍' },
  { url: '/assets/image2.jpg', alt: 'Image 2', caption: 'The greatest window cleaners of all time' },
  { url: '/assets/image3.webp', alt: 'Image 3', caption: 'Amazing meme' },
  // Add more images as needed
];

const ImageCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const carouselRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 1.0,
      }
    );

    const currentRef = carouselRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  useEffect(() => {
    if (!isVisible) return;

    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [isVisible]);

  const nextSlide = () => {
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((currentIndex - 1 + images.length) % images.length);
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="carousel" ref={carouselRef}>
      <Arrow direction="left" clickFunction={prevSlide} />
      <ImageSlide image={images[currentIndex]} />
      <Arrow direction="right" clickFunction={nextSlide} />
      <Dots currentIndex={currentIndex} goToSlide={goToSlide} totalSlides={images.length} />
    </div>
  );
};

export default ImageCarousel;
