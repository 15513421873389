import React from 'react';
import ImageCarousel from './ImageCarousel';
import GoogleMapComponent from './GoogleMapComponent';

const LandingPage = () => {
  const get_a_quote_page = () => {
    window.location.href = '/quote';
  };

  return (
    <main>
      <section className="hero">
        <h2>Welcome to Shore Shine Window Cleaning!</h2>
        <p>We will make your windows shine like never before.</p>
      </section>
      <section className="carousel-section">
        <ImageCarousel />
      </section>
      <section className="map-section">
      <h3>Our Location and Service Area</h3>
        <p>We proudly serve the beautiful Cape Cod area!</p>
        <GoogleMapComponent />
      </section>
      <section className="quote">
        <button onClick={get_a_quote_page}>Get a Quote</button>
      </section>
    </main>
  );
};

export default LandingPage;
