import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import LandingPage from './components/LandingPage';
import QuotePage from './components/QuotePage';
import AboutPage from './components/AboutPage';
import ShowcasePage from './components/ShowcasePage';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/showcase" element={<ShowcasePage />} />
          <Route path="/quote" element={<QuotePage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
